import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { ThemeProvider } from "styled-components"
import { Normalize } from "styled-normalize"
import GatsbyImage from "gatsby-image"

import { CheckList, Footer, Header } from "../../components"
import { ImageCircle } from "../../components/image-circle"
import { JuryNote } from "../../components/jury-note"
import { Quote } from "../../elements"
import ReviewFlow from "../../elements/review-flow"
import MoreActive from "../../images/migipedia/more-active.svg"
import { BodyText, Display, Highlight, Lead, Title } from "../../identity"
import { Container } from "../../layout"
import { theme } from "../../theme"

import { Link } from "../../identity/typography/link"

export default () => {
  const {
    snack: {
      childImageSharp: { fixed: snack },
    },
    reviewFlow: {
      childImageSharp: { fluid: reviewFlow },
    },
    bike: {
      childImageSharp: { fixed: bike },
    },
    discussing: {
      childImageSharp: { fixed: discussing },
    },
    recommender: {
      childImageSharp: { fluid: recommender },
    },
    stairs: {
      childImageSharp: { fixed: stairs },
    },
    philipp: {
      childImageSharp: { fixed: philipp },
    },
    logoCloud: {
      childImageSharp: { fluid: logoCloud },
    },
  } = useStaticQuery(graphql`
    query {
      snack: file(relativePath: { eq: "migipedia/02_snack_001.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      discussing: file(
        relativePath: { eq: "migipedia/01_diskutieren_007.jpg" }
      ) {
        childImageSharp {
          fixed(width: 500, height: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      recommender: file(
        relativePath: { eq: "migipedia/phone-landingpage-top.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reviewFlow: file(relativePath: { eq: "migipedia/review-flow.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bike: file(relativePath: { eq: "migipedia/03_bike_016.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      couch: file(relativePath: { eq: "migipedia/05_couch_010.jpg" }) {
        childImageSharp {
          fixed(width: 579, height: 387) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      stairs: file(relativePath: { eq: "migipedia/04_kitchen_012.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      philipp: file(relativePath: { eq: "migipedia/philipp-buehler.jpg" }) {
        childImageSharp {
          fixed(width: 400, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logoCloud: file(relativePath: { eq: "migipedia/logo-cloud.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <title>smartive — Eingaben Best of Swiss Web 2021</title>
        </Helmet>
        <Normalize />
        <Header
          heading="Eingabe Best of Swiss Web 2021"
          subheading="Kategorie Marketing"
        />

        <Container>
          <Display>
            <Highlight>MIGIPEDIA</Highlight> – 10 Jahre User im Mittelpunkt
          </Display>
          <Lead>
            Seit 2010 stehen bei Migipedia – der Community der Migros –
            Kundinnen und Kunden im Mittelpunkt. Die Ansprüche der User an die
            Plattform haben sich in dieser Zeit verständlicherweise immer wieder
            verändert. Mit dem Migipedia-Relaunch von 2020 wird diesen
            Veränderungen einmal mehr Rechnung getragen. Mit Erfolg: Die
            Community ist heute so lebendig wie nie zuvor.
          </Lead>
          <Lead>
            Kundenreaktionen im Minutentakt, auf 11 digitalen Touchpoints
            präsent, Lizenzkosten von 350 000 Franken auf null reduziert und 21
            Mio. Umsatz mit Produkten, welche mit Kundinnen und Kunden
            entwickelt wurden.{" "}
            <Link href="https://migipedia.migros.ch" target="_blank">
              Migipedia.ch
            </Link>{" "}
            ist seit 10 Jahren fester Bestandteil des digitalen Marketings der
            Migros und schafft Werte für Kundinnen und Kunden sowie fürs
            Unternehmen.
          </Lead>
        </Container>

        <ImageCircle
          images={{
            left: stairs,
            right: snack,
            center: recommender,
          }}
        />

        <Container>
          <BodyText>
            <CheckList
              items={[
                "elf digitale Touchpoints sind heute Teil des Community-Ökosystems der Migros.",
                "12,5% mehr User-Interaktion im Durchschnitt mit jedem digitalen Touchpoint, der 2020 zusätzlich erschlossen werden konnte. An Toptagen sind dies bis zu 1200 Interaktionen innert 24 Stunden – also beinah jede Minute eine Interaktion.",
                "60% mehr Produktbewertungen im Vergleich zum Vorjahr auf Migipedia sind auf die 2020 überarbeitete UX und UI sowie personalisierte Content-Empfehlungen zurückzuführen.",
                "350 000 Franken jährliche Lizenzkosten können durch die Ablösung der bisherigen SaaS-Lösung eingespart und die Betriebskosten so um über 90% reduziert werden.",
                "21 Mio. Franken Umsatz mit Produkten, bei denen Kundinnen und Kunden mitbestimmen konnten.",
              ]}
            />
          </BodyText>
        </Container>

        <Quote
          author="Philipp Bühler, Senior Product Owner Migipedia & Brand"
          photo={philipp}
        >
          &laquo;Migipedia ist eine Erfolgsgeschichte mit klarem Kundenfokus.
          Noch nie war die Community so aktiv wie heute, und dies bei deutlich
          reduzierten Betriebskosten.&raquo;
        </Quote>

        <Container>
          <Title number="01">
            Wenn Migipedia <Highlight>eine App</Highlight> wäre
          </Title>
          <BodyText>
            Beinah 80% der Nutzerinnen und Nutzer besuchen Migipedia mit einem
            Smartphone. Eine{" "}
            <Link
              href="https://www.emarketer.com/content/the-majority-of-americans-mobile-time-spent-takes-place-in-apps"
              target="_blank"
            >
              aktuelle Studie
            </Link>{" "}
            geht davon aus, dass User während der Nutzung eines mobile Gerätes
            zu knapp 90% in Apps befindet, wohingegen nur etwa 10% der Zeit für
            das Browsen im Internet verwendet wird. Was, wenn Migipedia eine App
            wäre?
          </BodyText>
          <BodyText>
            Mit dieser Fragestellung im Hinterkopf wurden UX und UI
            überarbeitet. Im Mittelpunkt stand dabei die Verbindung von
            User-generated Content mit Migros-Produkten. Auf der Startseite
            werden Usern unterschiedliche Produkte empfohlen. Ein neu
            entwickeltes, involvierendes Nutzererlebnis beim Bewerten nimmt
            Besucherinnen und Besucher an der Hand und führt sie durch den
            Bewertungsprozess mit jeweils drei Produkten.{" "}
          </BodyText>
          <BodyText>
            Das Resultat: Kundinnen und Kunden bewerten mehr Produkte und
            benötigen dafür weniger Zeit. Die durchschnittliche Zeit, welche
            User für Bewertungen aufwenden, wurde halbiert, ohne dass die
            Bewertungen selbst an Qualität verloren hätten.
          </BodyText>
          <BodyText>
            Besonders auf der Homepage, aber auch auf den Detailseiten finden
            sich Transition-, Scroll- und Swipe-Patterns, welche von Apps
            inspiriert sind. Der Umfang dieser Experience kann –
            verständlicherweise – mit einem Mobiltelefon im vollen Umfang erlebt
            werden. Zudem ist Migipedia die erste digitale Plattform der Migros,
            welche in Sachen Bildsprache, Tonalität, Typografie sowie
            dreieckigem Bild-Einschnitt, der sich aus dem Migros-M ableitet,
            voll und ganz der neuen Migros-Kommunikationshandschrift entspricht.
          </BodyText>
        </Container>

        <Container>
          <Title number="02">
            Kleine Recommender, <Highlight>grosse Wirkung</Highlight>
          </Title>
          <BodyText>
            Ein Produkt ernsthaft bewerten kann nur, wer es kennt. Ein Produkt
            kennt man, wenn man es ausprobiert hat. Genau auf dieser Logik
            basiert einer von mehreren Content-Recommendern auf Migipedia. Wer
            seinen Community-Account mit seiner Cumulus-Karte verknüpft, bekommt
            auf der Startseite jeweils drei Produkte seines letzten Einkaufs
            gezeigt. Charmant werden User gefragt, ob sie diese Produkte
            bewerten möchten. Anschliessend steigen sie in den zuvor
            beschriebenen Bewertungsprozess ein.
          </BodyText>
          <ReviewFlow />

          <BodyText>
            Nicht nur der letzte Einkauf, sondern auch oft gekaufte Produkte
            oder Produkte, die von anderen fleissig bewertet werden, sowie
            Aktionen werden den Community-Mitgliedern empfohlen. Je nach
            Login-Status kommen unterschiedliche Content-Empfehlungen zum
            Einsatz. Neben Reihenbewertungen (User bewerten mehrere Produkte
            hintereinander) konnte seit Go Live eine Steigerung der Bewertungen
            um 60% gemessen werden. Grund dafür: die Produktempfehlungen. Dies
            zeigt eine Auswertung der Kaufdaten von Migros-Kundinnen und -Kunden
            mit ihren Produktbewertungen.
          </BodyText>
        </Container>

        <ImageCircle
          images={{
            left: discussing,
            right: bike,
            center: reviewFlow,
          }}
        />

        <Container>
          <Title number="03">
            Lizenzkosten <Highlight>eliminiert</Highlight>
          </Title>
          <BodyText>
            Um die Kundenorientierung sowie Recommender zu ermöglichen, war eine
            Ablösung der bestehenden Software as a Service-Community-Lösung
            notwendig. Insgesamt 185 000 Franken investierte die Migros in die
            neue Technik, basierend auf Next.js mit React, GraphQL als
            Datenquelle und diversen Anbindungen weiterer Migros-spezifischer
            Schnittstellen. Jetzt ist Migipedia lizenzfrei, und die Migros spart
            damit jährliche Lizenzkosten von 350 000 Franken ein. Die
            Betriebskosten konnten so um 90% reduziert werden.
          </BodyText>
        </Container>

        <Container>
          <GatsbyImage fluid={logoCloud} />
        </Container>

        <Container>
          <Title number="04">
            Von der Plattform hin zum <Highlight>Community-Service</Highlight>
          </Title>
          <BodyText>
            Ein Rezept auf Migusto bewerten, eine Produktfrage in der Migros-App
            stellen oder beantworten, einen Einkauf auf SportXX bewerten, an
            einem Produkttest auf Migros.ch teilnehmen oder im Forum auf
            Migipedia mitdiskutieren – nach dem Motto «Fischen, wo die Fische
            sind, und nicht dort, wo die Migros den schönsten Angelplatz
            vermutet» finden sich heute Migipedia-Community-Features auf elf
            unterschiedlichen digitalen Touchpoints der Migros. Der Erfolg
            dieser Modularisierung lässt sich messen: Mit jeder Plattform, die
            zusätzlich mit Community-Features erschlossen werde konnte, wurden
            die User-Interaktionen im Durchschnitt um 12% erhöht. Im Schnitt
            wird aktuell alle 2½ Minuten ein Produkt bewertet. Am fleissigsten
            war die Community am 23. Mai 2020 – beinah im Minutentakt
            interagierten User an diesem Tag dank modularisierten
            Community-Features über elf digitale Touchpoints hinweg mit der
            Migros.
          </BodyText>
        </Container>

        <Container>
          <MoreActive />
        </Container>

        <Container>
          <Title number="05">
            So <Highlight>lebendig</Highlight> wie nie zuvor
          </Title>
          <BodyText>
            Der Shift von Migipedia als Plattform hin zu einem modularen
            Community-Service in Verbindung mit der Eliminierung von
            Lizenzkosten, modernster Web-Technik, Content-Empfehlungen sowie
            komplett überarbeiteter UX und UI sind zusammengefasst die
            Neuerungen im letzten Jahr, welche dazu beigetragen haben, dass die
            Migros-Community im zehnten Jahr so lebendig ist wie noch nie. Und
            das bei reduzierten Betriebskosten.
          </BodyText>
          <BodyText>
            Dass sich Kunden-Involvement auch direkt auf den Umsatz auswirkt,
            zeigen die 21 Mio., welche die Migros 2020 mit Produkten erzielte,
            deren Ideen von Kundinnen und Kunden stammten oder bei denen sie
            mitbestimmen konnten.
          </BodyText>
        </Container>

        <JuryNote />

        <Footer />
      </>
    </ThemeProvider>
  )
}
